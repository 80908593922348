<template>
  <div class="component-upload-image">
    <!-- <el-upload ref="upload" :show-file-list="false" action="#" :auto-upload="false" :on-change="handleUpload">
      <div style="display:flex">
        <el-button size="small" type="primary">点击上传</el-button>
      </div>
    </el-upload>
    <div class="el-upload__info">
      <span class="el-upload__tip" slot="tip" v-if="showTip">
        只能上传jpg/png格式文件，文件不能超过1MB
      </span>
      <img v-if="dialogImageUrl" :src="dialogImageUrl" @click="dialogVisible=true" />
      <i v-if="dialogImageUrl" @click="dialogImageUrl=''" class="el-icon-delete"></i>
    </div> -->
    <!-- res.records.url -->
    <el-upload :action="uploadImgUrl" :on-success="handleUploadSuccess" :data="data" :file-list="fileList" :before-upload="handleBeforeUpload" :limit="limit" :on-error="handleUploadError" :on-exceed="handleExceed" name="file" :on-remove="handleRemove" :show-file-list="false" :headers="headers" :on-preview="handlePictureCardPreview" :class="{hide: this.fileList.length >= this.limit}">
      <el-button size="small" type="primary">点击上传( {{index}} / 1 )</el-button>
    </el-upload>
    <div class="el-upload__info">
      <span class="el-upload__tip" slot="tip" v-if="showTip">
        只能上传jpg/png格式文件，文件不能超过1MB
      </span>
      <img v-if="dialogImageUrl" :src="dialogImageUrl" @click="dialogVisible=true" />
      <i v-if="dialogImageUrl" @click="remove" class="el-icon-delete"></i>
    </div>

    <!-- 上传提示 -->

    <el-dialog :visible.sync="dialogVisible" title="预览" width="800" append-to-body>
      <img :src="dialogImageUrl" style="display: block; max-width: 100%; margin: 0 auto" />
    </el-dialog>
  </div>
</template>

<script>
// import { getToken } from "@/utils/auth";

export default {
  props: {
    types: {
      type: String,
    },
    value: [String, Object, Array],
    // 图片数量限制
    limit: {
      type: Number,
      default: 1,
    },
    // 大小限制(MB)
    fileSize: {
      type: Number,
      default: 1,
    },
    // 文件类型, 例如['png', 'jpg', 'jpeg']
    fileType: {
      type: Array,
      default: () => ['png', 'jpg'],
    },
    // 是否显示提示
    isShowTip: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      index: 0,
      dialogImageUrl: '',
      dialogVisible: false,
      hideUpload: false,
      data: {
        hotelId: '79215978078289930',
        keyPrefix: 'mall/images',
      },
      uploadImgUrl:
        process.env.VUE_APP_BASE_API + '/system/aliyun/oss/uploadImage', // 上传的图片服务器地址
      headers: {
        Authorization: this.getToken(),
      },
      fileList: [],
    }
  },
  watch: {
    value: {
      handler(val) {
        if (val) {
          // 首先将值转为数组
          const list = Array.isArray(val) ? val : this.value.split(',')
          // 然后将数组转为对象数组
          this.fileList = list.map((item) => {
            if (typeof item === 'string') {
              item = { name: item, url: item }
            }
            return item
          })
        } else {
          this.fileList = []
          return []
        }
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    // 是否显示提示
    showTip() {
      return this.isShowTip && (this.fileType || this.fileSize)
    },
  },
  methods: {
    remove() {
      this.fileList = []
      this.dialogImageUrl = ''
      this.index = 0
      this.$emit('remove', this.types)
    },
    handleUpload(file) {
      let url = URL.createObjectURL(file.raw)
      this.dialogImageUrl = url
      this.$emit('imgurl', { url, keyName: this.types })
    },
    getToken() {
      return sessionStorage.getItem('access_token')
    },
    // 删除图片
    handleRemove(file, fileList) {
      const findex = this.fileList.map((f) => f.name).indexOf(file.name)
      if (findex > -1) {
        this.fileList.splice(findex, 1)
        this.$emit('remove', this.listToString(this.fileList))
      }
    },
    // 上传成功回调
    handleUploadSuccess(res) {
      console.log(res)
      this.fileList.push({ name: res.records.fileName, url: res.records.url })
      this.dialogImageUrl = res.records.url
      this.index = 1
      this.$emit('input', this.listToString(this.fileList))
      this.$emit('imgurl', {
        url: res.records.url,
        keyName: this.types,
        imgType: res.records.fileName.substr(res.records.fileName.length - 3),
      })
      console.log(this.fileList)
      this.loading.close()
    },
    // 上传前loading加载
    handleBeforeUpload(file) {
      let isImg = false
      if (this.fileType.length) {
        let fileExtension = ''
        if (file.name.lastIndexOf('.') > -1) {
          fileExtension = file.name.slice(file.name.lastIndexOf('.') + 1)
        }
        isImg = this.fileType.some((type) => {
          if (file.type.indexOf(type) > -1) return true
          if (fileExtension && fileExtension.indexOf(type) > -1) return true
          return false
        })
      } else {
        isImg = file.type.indexOf('image') > -1
      }

      if (!isImg) {
        this.$message.error(
          `文件格式不正确, 请上传${this.fileType.join('/')}图片格式文件!`
        )
        return false
      }
      if (this.fileSize) {
        const isLt = file.size / 1024 / 1024 < this.fileSize
        if (!isLt) {
          this.$message.error(`上传头像图片大小不能超过 ${this.fileSize} MB!`)
          return false
        }
      }
      this.loading = this.$loading({
        lock: true,
        text: '上传中',
        background: 'rgba(0, 0, 0, 0.7)',
      })
    },
    // 文件个数超出
    handleExceed() {
      this.$message.error(`上传文件数量不能超过 ${this.limit} 个!`)
    },
    // 上传失败
    handleUploadError() {
      this.$message({
        type: 'error',
        message: '上传失败',
      })
      this.loading.close()
    },
    // 预览
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    // 对象转成指定字符串分隔
    listToString(list, separator) {
      let strs = ''
      separator = separator || ','
      for (let i in list) {
        strs += list[i].url + separator
      }
      return strs != '' ? strs.substr(0, strs.length - 1) : ''
    },
  },
}
</script>
<style scoped lang="scss">
.component-upload-image {
  height: 40px;
  display: flex;
  .el-upload__info {
    position: relative;
    height: 40px;
    display: flex;
    align-items: center;
    .el-upload__tip {
      margin: 0 10px;
    }
    img {
      width: 40px;
      height: 40px;
      cursor: pointer;
    }
    .el-icon-delete {
      position: absolute;
      right: 12px;
      bottom: -15px;
      cursor: pointer;
    }
  }
}
// .el-upload--picture-card 控制加号部分
::v-deep.hide .el-upload--picture-card {
  display: none;
}
// 去掉动画效果
::v-deep .el-list-enter-active,
::v-deep .el-list-leave-active {
  transition: all 0s;
}
::v-deep .el-upload-list .el-upload-list--text {
  display: none;
}
::v-deep .el-list-enter,
.el-list-leave-active {
  opacity: 0;
  transform: translateY(0);
}
</style>

