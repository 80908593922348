<template>
  <div style="background-color: #ffffff">
    <el-descriptions :column='1' :colon="false" size="medium" labelClassName="label-title" contentClassName="content">
      <h2 slot="title">一.公司信息</h2>
      <el-descriptions-item label="酒店名称">{{incomInfo.hotelName}}</el-descriptions-item>
      <el-descriptions-item label="公司名称">{{incomInfo.custname}}</el-descriptions-item>
      <el-descriptions-item label="公司简称">{{incomInfo.custanothername}}</el-descriptions-item>
      <el-descriptions-item label="营业执照">{{incomInfo.companyno}}</el-descriptions-item>
      <el-descriptions-item label="公司地址">{{incomInfo.province }}{{incomInfo.city}}{{incomInfo.area}}</el-descriptions-item>
      <el-descriptions-item label="执照有效期">{{incomInfo.bslicenseeffect}}{{incomInfo.validtime}}</el-descriptions-item>
      <el-descriptions-item label="法人姓名">{{incomInfo.legalname}}</el-descriptions-item>
      <el-descriptions-item label="法人证件类型">{{incomInfo.certifitype}}</el-descriptions-item>
      <el-descriptions-item label="证件号码">{{incomInfo.certifino}}</el-descriptions-item>
      <el-descriptions-item label="证件有效期">{{incomInfo.certifidate}}{{incomInfo.certifieffect}}</el-descriptions-item>
      <el-descriptions-item label="法人手机号">{{incomInfo.custanothername}}</el-descriptions-item>
      <el-descriptions-item label="法人邮箱">{{incomInfo.email}}</el-descriptions-item>

    </el-descriptions>
    <el-descriptions :column='1' :colon="false" size="medium" labelClassName="label-title" contentClassName="content">
      <h2 slot="title">二.账户信息</h2>
      <el-descriptions-item label="结算方式">{{incomInfo.settleaccounttype}}</el-descriptions-item>
      <el-descriptions-item label="结算户名">{{incomInfo.bankaccountname}}</el-descriptions-item>
      <el-descriptions-item label="结算账号">{{incomInfo.bankaccountno}}</el-descriptions-item>
      <el-descriptions-item label="开户支行">
        {{incomInfo.bankname}}
      </el-descriptions-item>
      <el-descriptions-item label="开户地址">{{incomInfo.bankprovinc}}{{incomInfo.bankcity}}</el-descriptions-item>
    </el-descriptions>
    <!-- <el-descriptions :column='1' :colon="false" size="medium">
      <h2 slot="title">三.图片信息</h2>
      <el-descriptions-item label="用户名">kooriookami</el-descriptions-item>
      <el-descriptions-item label="手机号">18100000000</el-descriptions-item>
      <el-descriptions-item label="居住地">苏州市</el-descriptions-item>
      <el-descriptions-item label="备注">
        <el-tag size="small">学校</el-tag>
      </el-descriptions-item>
      <el-descriptions-item label="联系地址">江苏省苏州市吴中区吴中大道 1188 号</el-descriptions-item>
    </el-descriptions> -->
  </div>
</template>

<script>
import { getMerchantINfo, getIcomingInfo } from '@/api/system/ysManagement'
export default {
  data() {
    return {
      incomInfo: {},
    }
  },
  mounted() {
    this.getMerchantInfo()
    this.getIcomingInfo(this.$route.query.id)
  },
  methods: {
    // 查看信息
    async getMerchantInfo() {
      try {
        const res = await getMerchantINfo({
          hotelId: this.$route.query.hotelId,
        })
        this.incomInfo = res
        // console.log('商户信息：', res)
      } catch (error) {
        console.log('查看商户信息失败：', error)
      }
    },
    async getIcomingInfo(id) {
      try {
        const res = await getIcomingInfo({ id })
        // console.log('pc进件信息byId：', res)
        this.incomInfo = { ...this.incomInfo, ...res.data }
        // console.log(this.IncomForm)
      } catch (error) {
        console.log(error)
      }
    },
  },
}
</script>

<style>
.label-title {
  width: 130px;
  height: 40px;
  text-align: right;
  vertical-align: middle;
  float: left;
  font-size: 14px;
  color: #606266;
  line-height: 40px;
  padding: 0 12px 0 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.content {
  line-height: 40px;
}
</style>