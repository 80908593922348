import axios from 'axios'
import { urlObj } from '@/api/interface'
import {
    getBankLists,
    getMerchantINfo,
    getIcomingInfo,
    postIcomeform,
} from '@/api/system/ysManagement'
const pcIncomMixins = {
    data() {
        let _this = this
        return {
            companyaddress: [], // 公司地址级联选择框选择的值
            uploadImgList: [
                // 自定义图片集合，picType后端提供
                {
                    label: '营业执照',
                    keyvalue: 'businesslicense',
                    businesslicense: '',
                    picType: 'PIC_TYPE_19',
                    imgType: '',
                },
                {
                    label: '法人身份证正面',
                    keyvalue: 'legalFront',
                    legalFront: '',
                    picType: 'PIC_TYPE_00',
                    imgType: '',
                },
                {
                    label: '法人身份证反面',
                    keyvalue: 'legalReverse',
                    legalReverse: '',
                    picType: 'PIC_TYPE_30',
                    imgType: '',
                },
                {
                    label: '开户许可证',
                    keyvalue: 'openingpermit',
                    openingpermit: '',
                    picType: 'PIC_TYPE_37',
                    imgType: '',
                },
                {
                    label: '门口照片',
                    keyvalue: 'doorphoto',
                    doorphoto: '',
                    picType: 'PIC_TYPE_34',
                    imgType: '',
                },
                {
                    label: '经营场所照片',
                    keyvalue: 'businesspremisesphotos',
                    businesspremisesphotos: '',
                    picType: 'PIC_TYPE_50',
                    imgType: '',
                },
                {
                    label: '法人手持身份证',
                    keyvalue: 'legalIdcard',
                    legalIdcard: '',
                    picType: 'PIC_TYPE_33',
                    imgType: '',
                },
                {
                    label: '客户协议',
                    keyvalue: 'customerAgreement',
                    customerAgreement: '',
                    picType: 'PIC_TYPE_38',
                    imgType: '',
                },
            ],
            certifitypes: [{ label: '身份证', value: '00' }], // 证件类型，目前提供一种
            bankLists: [], // 银行输入框列表
            searchBankloading: false, // 银行搜索loading
            pickerCertifidate: {
                // 有效期的日期限制
                disabledDate(time) {
                    return time.getTime() < Date.now() - 8.64e7
                },
            },
            IncomForm: {
                // 表单集合数据
                hotelId: '',
                hotelName: '',
                custanothername: '',
                province: '',
                city: '',
                area: '',
                bankprovinc: '',
                bankcity: '',
                merchantIncomingFile: [],
                bankInfo: '',
                settleaccounttype: 'WITHDRAWAL_PLATFORM',
                // businesslicense: '',
                // legalFront: '',
                // legalReverse: '',
                // openingpermit: '',
                // doorphoto: '',
                // businesspremisesphotos: '',
                // legalIdcard: '',
                // customerAgreement: '',

            },
            merchantIncomingFile: [], //图片路径
            addressProp: {
                // 地域列表
                lazy: true,
                checkStrictly: false,
                async lazyLoad(node, resolve) {
                    let { value, hasChildren, level } = node
                    if (hasChildren === false) return resolve([])
                    if (value) {
                        value = value.split(',')[0]
                    } else {
                        value = -1
                    }
                    let data
                    try {
                        data = await _this.getRegionList(value, level)
                    } catch (e) {
                        console.error(e)
                    }
                    resolve(data)
                },
            },
            provinceList: [], // 省份列表
            cityList: [], //城市列表
            bankprovinc: '', // 省份
            bankcity: '', //城市
            rules: {
                // 验证规则
                custanothername: [
                    { required: true, message: '请输入公司简称', trigger: 'blur' },
                ],
                companyaddress: [
                    { required: true, message: '请输入公司地址', trigger: 'blur' },
                ],
                companyno: [
                    { required: true, message: '请输入营业执照', trigger: 'blur' },
                ],
                legalname: [
                    { required: true, message: '请输入法人姓名', trigger: 'blur' },
                ],
                certifino: [
                    { required: true, message: '请输入法人证件号码', trigger: 'blur' },
                ],
                bslicenseeffect: [
                    { required: true, message: '请输入执照生效期', trigger: 'blur' },
                ],
                validtime: [
                    { required: true, message: '请输入执照有效期', trigger: 'blur' },
                ],
                certifieffect: [
                    { required: true, message: '请输入证件生效期', trigger: 'blur' },
                ],
                certifidate: [
                    { required: true, message: '请输入证件有效期', trigger: 'blur' },
                ],
                contactphone: [
                    { required: true, message: '请输入法人手机号码', trigger: 'blur' },
                ],
                bankaccountname: [
                    { required: true, message: '请输入结算户名', trigger: 'blur' },
                ],
                bankaccountno: [
                    { required: true, message: '请输入结算账号', trigger: 'blur' },
                ],
                bankInfo: [
                    { required: true, message: '请输入开户支行', trigger: 'change' },
                ],

                businesslicense: [
                    { required: true, message: '请上传营业执照', trigger: 'change' },
                ],
                legalFront: [
                    { required: true, message: '请上传法人身份证正面', trigger: 'change' },
                ],
                legalReverse: [
                    { required: true, message: '请上传法人身份证反面', trigger: 'change' },
                ],
                openingpermit: [
                    { required: true, message: '请上传开户许可证', trigger: 'change' },
                ],
                doorphoto: [
                    { required: true, message: '请上传门口照片', trigger: 'change' },
                ],
                businesspremisesphotos: [
                    { required: true, message: '请上传经营场所照片', trigger: 'change' },
                ],
                legalIdcard: [
                    { required: true, message: '请上传法人手持身份证', trigger: 'change' },
                ],
                customerAgreement: [
                    { required: true, message: '请上传客户协议', trigger: 'change' },
                ],
            },
        }
    },

    watch: {
        // 公司地址级联选择框
        companyaddress(val) {
            console.log(val);
            if (val.length > 0) {
                this.IncomForm.province = val[0].slice(
                    val[0].indexOf(',') + 1,
                    val[0].length
                )
                this.IncomForm.city = val[1].slice(val[1].indexOf(',') + 1, val[1].length)
                this.IncomForm.area = val[2].slice(val[2].indexOf(',') + 1, val[2].length)
            }
            // localStorage.setItem('companyaddress', JSON.stringify(val))

        },
        // 省选择框
        bankprovinc(value) {
            // localStorage.setItem('bankprovinc', value)
            if (value) {
                let parentId = value.slice(0, value.indexOf(','))
                this.IncomForm.bankprovinc = value.slice(
                    value.indexOf(',') + 1,
                    value.length
                )

                this.getCityList(parentId)
                // this.bankcity = ''
            }
        },
        // 城市选择框
        bankcity(value) {
            // localStorage.setItem('bankcity', value)
            this.IncomForm.bankcity = value.slice(
                value.indexOf(',') + 1,
                value.length
            )
        },
    },
    methods: {
        // 表单提交
        onSubmit() {
            console.log('submit!')
            this.merchantIncomingFile = []
            this.uploadImgList.forEach((item, index) => {
                // console.log(this.$refs[`form-${item.keyvalue}`]);
                // 处理成后端要求的格式
                let obj = {
                    fileType: '',
                    fileUrls: [],
                }
                let fileUrls = [
                    {
                        suffix: '',
                        url: '',
                    },
                ]
                obj.fileType = item.picType
                fileUrls[0].suffix = item.imgType
                fileUrls[0].url = item[item.keyvalue]
                obj.fileUrls = fileUrls
                this.merchantIncomingFile.push(obj)
            })
            // return
            this.IncomForm.merchantIncomingFile = this.merchantIncomingFile // 图片array集合
            this.IncomForm.mobile = this.IncomForm.contactphone
            this.IncomForm.bankmobile = this.IncomForm.contactphone
            // this.IncomForm.bankname = '中国工商银行股份有限公司深圳龙华支行'  // 测试进件

            console.log('IncomForm', this.IncomForm);

            // 上传图片验证 start
            let valid = true
            let label = ''
            this.uploadImgList.some((item, index) => {
                if (!item[item.keyvalue]) {
                    // console.log(this.$refs[`form-${item.keyvalue}`]);
                    valid = false
                    label = this.$refs[`form-${item.keyvalue}`][0].label
                    return true    // 返回不再遍历
                }

            })
            if (!valid) {
                this.$message.error('请上传' + label)
            } else {
                this.$refs.IncomForm.validate((valid) => {
                    if (valid) {

                        postIcomeform(this.IncomForm).then((res) => {
                            console.log('提交结果：', res)
                            if (res.success) {
                                this.goBack()
                                localStorage.setItem('iconcomForm', {})
                            }
                        })
                    } else {
                        console.log('IncomForm', this.IncomForm)
                        return false
                    }
                })
            }

        },
        // getMerchantINfo(hotelId) {
        //     getMerchantINfo({ hotelId }).then(res => {
        //         console.log(res);
        //     })
        // },
        // 查看进件信息
        async getIcomingInfo(id) {
            try {
                const res = await getIcomingInfo({ id })
                // console.log('pc进件信息：', res)
                this.IncomForm = { ...this.IncomForm, ...res.data }
                // console.log(this.IncomForm)
            } catch (error) {
                console.log(error)
            }
        },
        // 获取省级列表
        getProvinclist() {
            const url = urlObj.regionList + `/-1`
            this.$axios.get(url, {}).then((res) => {
                this.provinceList = res.children
            })
        },
        // 获取市级列表
        getCityList(parentId) {
            const url = urlObj.regionList + `/${parentId}`
            this.$axios.get(url, {}).then((res) => {
                this.cityList = res.children
            })
        },
        // 获取省市县
        getRegionList(parentId, level) {
            return new Promise((resolve) => {
                const url = urlObj.regionList + `/${parentId}`
                this.$axios.get(url, {}).then((res) => {
                    if (res.success) {
                        let addressList = res.children
                        addressList = addressList.map((item) => {
                            if (level === 2)
                                return {
                                    value: item.id + ',' + item.regionName,
                                    label: item.regionName,
                                    leaf: true,
                                }
                            return {
                                value: item.id + ',' + item.regionName,
                                label: item.regionName,
                            }
                        })
                        resolve(addressList)
                    }
                })
            })
        },
        // 上传图片组件的接收事件
        getimgurl(fileInfo) {
            console.log(fileInfo);
            this.uploadImgList.map((item, index) => {
                if (item.keyvalue === fileInfo.keyName) {
                    item[fileInfo.keyName] = fileInfo.url
                    item.imgType = fileInfo.imgType
                    // this.IncomForm[item.keyvalue] = fileInfo.url  // 多余字段看后端会不会屏蔽
                    console.log(this.IncomForm);
                    this.$refs.IncomForm.clearValidate(item.keyvalue)
                }
            })
        },
        // 删除图片
        getRemove(type) {
            console.log(type);
            // this.IncomForm[type] = ''
            // console.log(this.IncomForm);
            let removeIndex = 0
            this.uploadImgList.map((item, index) => {
                if (item.keyvalue === type) {
                    removeIndex = index
                    item[item.keyvalue] = ''
                    item.imgType = ''
                }
            })
            this.IncomForm.merchantIncomingFile[removeIndex].fileType = ''
            this.IncomForm.merchantIncomingFile[removeIndex].fileUrls[0].suffix = ''
            this.IncomForm.merchantIncomingFile[removeIndex].fileUrls[0].url = ''


        },
        // 银行搜索
        remoteSearchBank(query) {
            // console.log(query)
            if (query) {
                this.getBankLists(query)
            }
        },
        // 开户支行
        bankChange(value) {
            console.log(value.split('|'))
            this.IncomForm.bankname = value.split('|')[0]
            this.IncomForm.banktype = value.split('|')[1]
        },
        // 根据关键字搜索银行
        async getBankLists(keyValue) {
            this.searchBankloading = true
            try {
                const res = await getBankLists({
                    page: 1,
                    limit: 20,
                    bankName: keyValue,
                })
                this.bankLists = res.records
                console.log('银行信息：', res)
                this.searchBankloading = false
            } catch (error) {
                this.searchBankloading = false
            }
        },
        // getCity() {
        //     axios.get('https://restapi.amap.com/v3/config/district?keywords=中国&subdistrict=3&key=ec543a86caa78f9d8cffa0784eb4942f',).then(res => {
        //         console.log(res);
        //     })
        // }
    },
}

export default pcIncomMixins
