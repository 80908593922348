<template>
  <div class="cont">
    <!-- 面包屑 -->
    <el-row class="crumbs-box">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>系统管理</el-breadcrumb-item>
        <el-breadcrumb-item to="/ys_management">银盛子商户管理</el-breadcrumb-item>
        <el-breadcrumb-item>{{ reversedContent }}</el-breadcrumb-item>
      </el-breadcrumb>
    </el-row>
    <el-page-header @back="goBack">
      <div slot="content" class="page-header-content">
        <span>{{ reversedContent }}</span>
        <el-button v-if="$route.query.action === 'edit'" type="primary" @click="saveBack">保存并返回</el-button>
      </div>
    </el-page-header>
    <el-row v-if="$route.query.action === 'edit'" style="background-color: #ffffff">
      <el-col :span="12">
        <el-form ref="IncomForm" :model="IncomForm" :rules="rules" label-width="130px">
          <h2>一.公司信息</h2>
          <el-form-item label="酒店名称">
            <span>{{IncomForm.hotelName }}</span>
          </el-form-item>
          <el-form-item label="公司名称">
            <span>{{IncomForm.custname || IncomForm.companyId }}</span>
          </el-form-item>
          <el-form-item label="公司简称" prop="custanothername">
            <el-input v-model="IncomForm.custanothername" placeholder="输入公司简称"></el-input>
          </el-form-item>
          <el-form-item label="营业执照" prop="companyno">
            <el-input v-model="IncomForm.companyno" placeholder="输入营业执照"></el-input>
          </el-form-item>
          <el-form-item label="公司地址" prop="companyaddress">
            <el-cascader placeholder="请选择公司地址" v-model="companyaddress" style="width: 100%" :props="addressProp" clearable></el-cascader>
            <el-input placeholder="请输入详细地址" v-model="IncomForm.companyaddress"></el-input>
          </el-form-item>
          <el-form-item label="执照有效期" required>
            <el-col :span="5">
              <el-form-item prop="bslicenseeffect">
                <el-date-picker type="date" placeholder="选择起始日期" value-format="yyyyMMdd" v-model="IncomForm.bslicenseeffect" style="width: 100%;"></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col class="line" :span="1" style="text-align: center">至</el-col>
            <el-col :span="5">
              <el-form-item prop="validtime">
                <el-date-picker type="date" :disabled="toLong" :picker-options="pickerCertifidate" placeholder="选择到期日期" value-format="yyyyMMdd" v-model="IncomForm.validtime" style="width: 100%;"></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="11" style="text-align: center">
              <el-checkbox v-model="toLong" @change="longChange" label="长期"></el-checkbox>
            </el-col>
          </el-form-item>
          <el-form-item label="法人姓名" prop="legalname">
            <el-input v-model="IncomForm.legalname" placeholder="请输入法人姓名"></el-input>
          </el-form-item>
          <el-form-item label="法人证件类型">
            <el-select style="width:100% " placeholder="请选择法人证件类型" clearable v-model="IncomForm.certifitype">
              <el-option v-for="item in certifitypes" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="证件号码" prop="certifino">
            <el-input v-model="IncomForm.certifino" placeholder="请输入法人证件号码"></el-input>
          </el-form-item>
          <el-form-item label="证件有效期" required>
            <el-col :span="5">
              <el-form-item prop="certifieffect">
                <el-date-picker type="date" placeholder="选择起始日期" value-format="yyyyMMdd" v-model="IncomForm.certifieffect" style="width: 100%;"></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col class="line" :span="1" style="text-align: center">至</el-col>
            <el-col :span="5">
              <el-form-item prop="certifidate">
                <el-date-picker type="date" :picker-options="pickerCertifidate" placeholder="选择到期日期" value-format="yyyyMMdd" v-model="IncomForm.certifidate" style="width: 100%;"></el-date-picker>
              </el-form-item>
            </el-col>

          </el-form-item>
          <el-form-item label="法人手机号" prop="contactphone">
            <el-input v-model="IncomForm.contactphone" placeholder="请输入法人手机号"></el-input>
          </el-form-item>
          <el-form-item label="法人邮箱" placeholder="请输入法人邮箱">
            <el-input v-model="IncomForm.email"></el-input>
          </el-form-item>
          <h2>二.账户信息</h2>
          <el-form-item label="结算方式">
            <el-radio-group v-model="settleways">
              <el-radio label="0">平台内提现</el-radio>
              <el-radio label="1">直接到银行卡</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="结算户名" prop="bankaccountname">
            <el-input v-model="IncomForm.bankaccountname" placeholder="请输入结算户名"></el-input>
          </el-form-item>
          <el-form-item label="结算账号" prop="bankaccountno">
            <el-input v-model="IncomForm.bankaccountno" placeholder="请输入结算账号"></el-input>
          </el-form-item>
          <el-form-item label="开户支行" prop="bankInfo">
            <el-select style="width:100%" clearable v-model="IncomForm.bankInfo" @change="bankChange" filterable remote reserve-keyword placeholder="请输入关键词搜索" :remote-method="remoteSearchBank" :loading="searchBankloading">
              <el-option v-for="item in bankLists" :key="item.id" :label="item.bankName" :value="item.bankName + '|' + item.rowNo ">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="开户地址">
            <el-select @focus="getProvinclist" v-model="bankprovinc" placeholder="请选择省份" clearable>
              <el-option v-for="item in provinceList" :key="item.id" :label="item.regionName" :value="item.id + ',' +item.regionName">
              </el-option>
            </el-select>
            <el-select v-model="bankcity" placeholder="请选择城市" clearable>
              <el-option v-for="item in cityList" :key="item.id" :label="item.regionName" :value="item.id + ',' + item.regionName">
              </el-option>
            </el-select>
          </el-form-item>
          <h2>三.上传图片</h2>
          <el-form-item v-for="(item, index) in uploadImgList" :label="item.label" :key="index" required :ref="`form-${item.keyvalue}`">
            <!-- <el-input v-model="IncomForm[item.keyvalue]" placeholder=""></el-input> -->
            <ImageUpload :ref="`${item.keyvalue}`" :types="item.keyvalue" @imgurl="getimgurl" @remove="getRemove" />
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit">提交审核</el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <pc-icom-info v-else />

  </div>
</template>

<script>
import pcIncomMixins from './mixins/pcIncomMixins'
import ImageUpload from '../../../components/local/ImageUpload/index.vue'
import pcIcomInfo from './components/pcIcomInfo.vue'
export default {
  mixins: [pcIncomMixins],
  components: {
    ImageUpload,
    pcIcomInfo,
  },
  data() {
    return {
      reversedContent: 'PC进件',
      toLong: false, // 是否长期
      action: 'edit', // 编辑
      settleways: '0', // 结算方式
    }
  },
  watch: {
    // 结算方式
    settleways(setway) {
      setway === '0'
        ? (this.IncomForm.settleaccounttype = 'WITHDRAWAL_PLATFORM')
        : (this.IncomForm.settleaccounttype = 'DIRECT_BANK_CARD')
    },
  },
  created() {
    // if(this.$route.query.action) {
    //   this.getIcomingInfo(this.$route.query.id)
    //   this.getProvinclist()
    // this.IncomDataEcho()
    // }
    // localStorage.clear('iconcomForm')
  },
  mounted() {
    if (this.$route.query.action === 'edit') {
      this.getIcomingInfo(this.$route.query.id)
      this.getProvinclist()
      this.IncomDataEcho()
    }
    // this.IncomDataEcho()
  },
  methods: {
    // 数据回显
    IncomDataEcho() {
      let iconcomFormStorage = localStorage.getItem('iconcomForm')
      if (
        iconcomFormStorage &&
        this.$route.query.id === JSON.parse(iconcomFormStorage).id
      ) {
        this.IncomForm = JSON.parse(iconcomFormStorage)
        if (this.IncomForm.validtime === '29991231') {
          this.toLong = true
        }
        this.IncomForm.settleaccounttype === 'WITHDRAWAL_PLATFORM'
          ? (this.settleways = '0')
          : (this.settleways = '1')
        this.uploadImgList.forEach((item, index) => {
          // console.log(item)
          if (this.IncomForm.merchantIncomingFile.length > 0) {
            item.imgType =
              this.IncomForm.merchantIncomingFile[index].fileUrls[0].suffix
            item[item.keyvalue] =
              this.IncomForm.merchantIncomingFile[index].fileUrls[0].url

            // console.log(this.$refs[item.keyvalue][0])
            let imgurl =
              this.IncomForm.merchantIncomingFile[index].fileUrls[0].url
            if (imgurl) {
              this.$refs[item.keyvalue][0].dialogImageUrl = imgurl
              this.$refs[item.keyvalue][0].fileList.push({ url: imgurl })
              this.$refs[item.keyvalue][0].index = 1
            }
          }
        })
        this.remoteSearchBank(this.IncomForm.bankname)

        if (JSON.parse(localStorage.getItem('companyaddress'))) {
          this.companyaddress = JSON.parse(
            localStorage.getItem('companyaddress')
          )
        }
        if (localStorage.getItem('bankprovinc') !== 'null') {
          this.bankprovinc = localStorage.getItem('bankprovinc')
        }
        if (localStorage.getItem('bankcity') !== 'null') {
          this.bankcity = localStorage.getItem('bankcity')
        }
        // console.log(JSON.parse(localStorage.getItem('companyaddress')) === null)
        // this.bankprovinc = localStorage.getItem('bankprovinc')
        // this.bankcity = localStorage.getItem('bankcity')
      } else {
        // this.companyaddress = null
        // this.bankprovinc = null
        // this.bankcity = null
      }
    },
    goBack() {
      this.$router.go(-1)
    },
    saveBack() {
      this.merchantIncomingFile = []
      this.uploadImgList.forEach((item, index) => {
        // console.log(this.$refs[`form-${item.keyvalue}`]);
        // 处理成后端要求的格式
        let obj = {
          fileType: '',
          fileUrls: [],
        }
        let fileUrls = [
          {
            suffix: '',
            url: '',
          },
        ]
        obj.fileType = item.picType
        fileUrls[0].suffix = item.imgType
        fileUrls[0].url = item[item.keyvalue]
        obj.fileUrls = fileUrls
        this.merchantIncomingFile.push(obj)
      })
      // return
      this.IncomForm.merchantIncomingFile = this.merchantIncomingFile // 图片array集合
      this.IncomForm.mobile = this.IncomForm.contactphone
      this.IncomForm.bankmobile = this.IncomForm.contactphone
      localStorage.setItem('iconcomForm', JSON.stringify(this.IncomForm))
      localStorage.setItem(
        'companyaddress',
        JSON.stringify(this.companyaddress)
      )
      localStorage.setItem('bankprovinc', this.bankprovinc)
      localStorage.setItem('bankcity', this.bankcity)

      // console.log(this.companyaddress)
      // console.log(this.bankprovinc)
      // console.log(this.bankcity)

      this.goBack()
    },
    // 选择长期
    longChange(e) {
      this.toLong = e
      if (e) {
        this.IncomForm.validtime = '29991231'
      } else {
        this.IncomForm.validtime = null
      }
    },
  },
}
</script>

<style lang="scss" scoped>
h2 {
  margin-left: 10px;
}
/deep/.el-page-header {
  .el-page-header__content {
    width: calc(100% - 100px);
    .page-header-content {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}
</style>
